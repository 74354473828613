<template>
  <div class="w-full">
    <div v-if="label || required" class="flex items-center mb-4">
      <Title v-if="label && showLabel" size="small" :title="label" class="text-blue-lighter" />

      <span v-if="required" class="ml-auto text-sm text-signal-red"> Required </span>

      <span v-if="invalidText" class="ml-auto text-sm text-signal-red">
        {{ invalidText }}
      </span>
    </div>

    <div>
      <Button
        kind="secondary"
        :class="
          items.filter((i) => i.checked).length > 0 && items.filter((i) => i.checked).length !== items.length
            ? 'capitalize'
            : ''
        "
        :capitalize="
          items.filter((i) => i.checked).length > 0 && items.filter((i) => i.checked).length !== items.length
            ? true
            : false
        "
        :label="selectedItems"
        @clicked="showModal = true"
      />
    </div>

    <Modal v-if="showModal" @closeModal="showModal = false">
      <Tile class="list-modal" border-radius="5px 5px 0 0" border="none">
        <div class="pt-4 pr-4 pl-4 mb-4">
          <div class="flex items-center w-full">
            <Title :title="`Selected ${label.toLowerCase()}:`" size="medium" />

            <Button class="mr-4 ml-auto" kind="link" label="Close" @clicked="showModal = false" />
          </div>

          <p
            :class="
              items.filter((i) => i.checked).length > 0 && items.filter((i) => i.checked).length !== items.length
                ? 'capitalize'
                : ''
            "
          >
            {{ selectedItems }}
          </p>
        </div>

        <div class="pr-4 pb-2 pl-4">
          <p>Search</p>
          <div class="flex">
            <input v-model="inputText" type="text" class="input__field" />

            <div class="flex gap-4 ml-auto">
              <Button kind="link" label="Select all" @clicked="markAllItems" />

              <Button kind="link" label="Deselect all" @clicked="unMarkAllItems" />
            </div>
          </div>
        </div>

        <div class="list">
          <p
            v-for="(item, itemIndex) of filteredItems"
            :key="itemIndex"
            class="list-item capitalize"
            @click="markItem(item.value)"
          >
            <input v-model="item.checked" type="checkbox" />

            <span>
              {{ item.value.toLowerCase() }}
            </span>
          </p>
        </div>
      </Tile>
    </Modal>
  </div>
</template>

<script>
import { Title, Button } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "MultiSelect",

  components: {
    Modal: () => import("@/components/Modal"),
    Tile: () => import("@/components/Tile"),
    Button,
    Title,
  },

  props: {
    items: {
      // { checked: boolean, value: string }[]
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    invalidText: {
      type: Boolean,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showModal: false,
      inputText: "",
    };
  },

  computed: {
    /**
     * @returns {string}
     */
    selectedItems() {
      if (this.items.filter((item) => item.checked).length === 0) {
        return `${this.label ? `No ${this.label.toLowerCase()} selected` : "Nothing selected"}`;
      }

      if (this.items.filter((item) => item.checked).length === this.items.length) {
        return `${this.label ? `All ${this.label.toLowerCase()} selected` : "Everything selected"}`;
      }

      const checkedItems = this.items.filter((item) => item.checked);

      const shownItems = checkedItems.splice(0, 3).map((elm) => elm.value);

      return `${shownItems.join(", ")} ${checkedItems.length > 0 ? ` + ${checkedItems.length}` : ""}`;
    },

    /**
     * @returns {{ checked: boolean, value: string }[]}
     */
    filteredItems() {
      if (this.inputText?.length) {
        return this.items.filter((item) => item.value.toLowerCase().includes(this.inputText.toLowerCase()));
      }

      return this.items;
    },
  },

  watch: {
    items: {
      deep: true,

      handler() {
        this.$emit("selectedItems", this.items);
      },
    },
  },

  methods: {
    /**
     * @param {string} item
     */
    markItem(item) {
      const itemIndex = this.items.findIndex((i) => i.value === item);

      if (typeof itemIndex === "number" && itemIndex !== -1) {
        this.items[itemIndex].checked = !this.items[itemIndex].checked;
      }
    },

    markAllItems() {
      log("markAllItems");
      const { items } = this;

      for (const item of items) {
        item.checked = true;
      }
    },

    unMarkAllItems() {
      const { items } = this;

      for (const item of items) {
        item.checked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

.list-modal {
  width: 100%;
  background-color: color(blue, base);
  border-radius: 0 0 5px 5px;

  .list {
    background-color: color(blue, base);
    position: absolute;
    border-radius: 0 0 5px 5px;
    z-index: 2000;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: auto;
    height: auto;
    max-height: 20rem;
    border: 1px solid color(blue, darker);

    &::-webkit-scrollbar {
      background-color: color(blue, darker);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: color(blue, base);

      &:hover {
        background-color: color(blue, lighter);
      }
    }

    .list-item {
      width: 100%;
      background-color: color(blue, base);
      border: 1px solid color(blue, darker);
      text-align: left;
      padding: 0.5rem;
      padding-left: 1rem;
    }
  }
}
</style>
